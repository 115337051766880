.share-link-dialog {
    padding: 20px 40px 20px;
    width: 540px;
}
.share-link-dialog .header-title {
    padding: 12px 0 16px;
}

.header-title b {
    font-weight: 900;
}

.link-wrapper {
    display: flex;
    align-items: center;
}

.link-wrapper .MuiInputBase-root {
    flex: 1;
}

.link-wrapper input{
    padding: 12px 6px;
    background: #f7fafc;
    border: 1px solid #e5eaf1;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10.44px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #3c7db7;
}

.link-wrapper .copy-button {
    padding-left: 18px;
    cursor: pointer;
    fill: #656565;
}

.link-wrapper .copy-button:hover {
    fill: #3c7db7;
}