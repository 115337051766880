.legend-wrapper {
    height: 139px;
    width: 130px;
    background: rgba(255, 255, 255, 0.9);

    position: absolute;
    bottom: 10px;
    right: 25px;
    z-index: 997;

    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
}

.legend-wrapper .color-pallet-wrapper {

}
.legend-wrapper .color-pallet-1 {
    min-width: 16px;
    height: 69px;
    background: linear-gradient(180deg, #93CFB8, #FFFFCD);
}

.legend-wrapper .color-pallet-2 {
    min-width: 16px;
    height: 52px;
    background: linear-gradient(180deg, #2E3790, #93CFB8);
}

.legend-wrapper .value-pallet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-right: 5px;
}
.value-pallet .value {
    font-family: 'Open Sans', sans-serif;;
    font-size: 12px;
    color: #626262;
    text-align: right;
}
.print .legend-wrapper {
    right: 10px!important;
}