.scope-tooltip{
    max-width: 460px;
    top: 150px!important;
}

.scope-list-wrapper{
    padding: 0;
}

.filter-radio{
    padding: 8px !important;
}

.filter-item .injected-svg{
    padding-right: 10px;
}

.maplevel{
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: flex-start;
    color: #6B6B6B;
    padding: 20px 25px 5px;
}

.level-item:hover{
    text-decoration: underline;
    cursor: pointer;
}

.filter-content, .scope-content{
    display: flex;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 15px 15px;
    border-radius: 5px;
}

.scope-content{
    background-color: rgba(196, 196, 196, 0.1);
}

.filter-item{
    text-align: start;
    font-size: 14px;
    cursor: pointer;
}

.filter-item .filterby-label{
    font-size: 11px;
    line-height: 15px;
    color: #686868;
    text-transform: capitalize;
}

.filter-item .filterby-label.blue{
    color: #2879B9;
}

.footer{
    padding: 15px 10px;
    border-top: 1px solid #C4C4C4;
    display: flex;
}

.footer-text{
    font-size: 12px;
    line-height: 14px;
    text-align: start;
    color: #000000;
}

.scope-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.scope-list {
    display: flex;
    padding-right: 0;
    align-items: flex-start;
    padding: 25px 25px 10px;
}

.scope-wrapper>.label {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #7A7A7A;
}
.scope-list .scope {
    display: flex;
    cursor: pointer;
    white-space: nowrap;
}
.scope-list .scope.show {
    animation: show .3s linear;
    animation-fill-mode: backwards;
}
.scope-list .scope.current>.value {
    font-weight: normal;
    font-weight: bold;
    color: #2879B9;
}
.scope-list .scope.hide {
    display: none;
}

.scope-list-item{
    font-weight: bold;
    font-size: 11px;
    color: #6B6B6B;
}
.scope>span.next {
    font-weight: normal!important;
    margin-left: 5px;
}

.next img.scope-arrow{
    width: 8px;
}

.scope-add {
    padding: 0 5px;
}
.scope-add button {
    background-color: #D8D8D8;
    border-radius: 0;
    padding: 0;
}
.scope-add button:hover {
    background-color: #D8D8D8;
}
.world-scope,
.country-scope,
.region-scope,
.province-scope,
.municipality-scope {
    margin-left: 8px;
    font-weight: normal;
    color: #686868;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
}

.key-elements{
    color: #2879B9;
    font-weight: bold;
    cursor: pointer;
}

span.value:hover{
    text-decoration: underline;
}

.region-scope{
    display: flex;
    justify-content: center;
}

.scope-select.country-margin,
.scope-select.region-margin,
.scope-select.province-margin{
    margin-left: 10px;
}

.close-btn {
    padding: 3px 6px;
    right: 7px;
    top: 8px;
}