body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
  line-height: 1.2;
}

* {
  font-family: 'Open Sans', sans-serif;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

#cookiescript_badge {
  opacity: 0.3;
}

#cookiescript_badge:hover {
  opacity: 0.8;
}

@media print {
  .no-print { display: none!important; }

  .info-pane-outer-wrapper {
    display: inline-block!important;
  }
  .leaflet-control-container {
    display: none!important;
  }

  .legend-wrapper {
    bottom: 15px!important;
    right: 15px!important;
  }

  .map-wrapper {
    display: flex!important;
    flex-direction: column!important;
    height: 100vh!important;
    width: auto!important;
    box-shadow: none!important;
    margin: 0!important;
    padding: 0!important;
  }

  .map-components-wrapper {
    width: auto!important;
    margin-bottom: 10px!important;
  }

  .map-components-wrapper:after {
    content: '';
  }

  .info-pane-outer-wrapper {
    position: unset!important;
    box-shadow: none!important;
    height: 52%!important;

    border-top: 1px solid #CACACA!important;
    padding-top: 10px!important;
  }
  .map-components-wrapper {
    height: calc(42% - 21px)!important;
  }
  .leaflet-container {
    height: 100%!important;
  }

  .info-pane-wrapper {
    width: auto!important;
    position: relative!important;
    height: 100%!important;
    box-shadow: none!important;
    padding: 0!important;
  }
  .info-pane_header {
    position: absolute!important;
    width: 50%!important;
    left: 0!important;

    display: flex!important;
    justify-content: flex-start!important;
  }
  .info-pane_header .province{
    padding: 25px 0 15px!important;
  }
  .info-pane_header .label {
    color: #000!important;
    font-family: 'Montserrat';
    font-weight: 300!important;
    font-size: 14px!important;
    text-align: left!important;
  }
  .info-pane_header .province-name {
    color: #2D2D2D!important;
    text-transform: uppercase!important;
    font-family: 'Montserrat';
    font-weight: 500!important;
    font-size: 24px!important;
    text-align: left!important;
    letter-spacing: 0.1em!important;
  }
  .chart-wrapper {
    position: absolute!important;
    right: 0!important;
    padding: 30px calc(25% - 125px)!important;
    margin: 0!important;
  }
  .jobs-info-wrapper_print {
    display: flex!important;
    justify-content: space-between!important;
    width: 100%!important;
    position: absolute!important;
    bottom: 10px!important;
    padding-bottom: 20px!important;
  }

  .jobs-info-wrapper_print .left {
    flex: 1!important;
  }
  .total_print {
    text-align: left!important
  }
  .jobs-info-wrapper_print .right {
    flex: 1!important;
    padding-top: 176px!important;
  }
  .info-table.top {
    margin: 40px 0 60px 0!important;
  }
  .info-table.top td{
    font-size: 16px;
  }
  .info-table.top .tc {
    font-size: 17px!important;
    padding: 0!important;
  }
  .info-table tr {
    height: 38px!important;
  }
  .tc {
    text-align: left!important;
    min-width: 160px!important;
  }

  td.td-icon {
    width: 25px!important;
  }
  td.td-percent {
    width: 25px!important;
  }

  .info-table.borderless td,
  tbody>tr:last-child>td {
    border: none!important;
  }
  .bold {
    font-weight: bold!important;
  }

  .print-header {
    background-color: #FFF!important;
    color: #2D2D2D;
    height: 6%!important;
    font-family: 'Montserrat'!important;
    text-align: left!important;

    display: flex!important;
    flex-direction: column!important;
    justify-content: space-between!important;
    padding: 10px 0px!important;
    padding-bottom: 1px!important;
  }
  .print-header>.subtitle {
    display: block!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: normal!important;
    font-size: 22px!important;
    letter-spacing: 0.1em!important;
    text-transform: uppercase!important;
    color: #2D2D2D!important;
  }
  .print-header>.subtitle-print {
    display: block!important;
    font-size: 12px!important;
    color: #000!important;
  }

  div[aria-label]:hover:after {
    display: none!important;
  }
}

@media screen and (max-width: 440px) {
  .leaflet-top, .leaflet-bottom {
    z-index: 997 !important;
  }
}

@media print and (color) {
  * {
     -webkit-print-color-adjust: exact;
     print-color-adjust: exact;
  }
}
