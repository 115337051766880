.radar-chart-wrapper {
    position: relative;
}

.chartjs-render-monitor{
    transform: rotate(13deg);
}

.radar-chart-wrapper .radar-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
}

.radar-icon::after {
    content: '';
    opacity: 0;
    width: 1px;
    height: 13px;
    background-color: #D6D6D6;
    position: absolute;
}

.radar-icon .tooltip{
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    color: #1187B3;
}

[id^='icon-index']{
    transition: all ease-out .25s;
}

.core {
    background-color: #3CBEF1;
    width: 22px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.enabling {
    background-color: #2F85B1;
    width: 22px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.radar-icon.rethink {
    top: 269px;
    right: 83px;
}
.radar-icon.rethink::after {
    transform: rotate(-12deg);
    top: -13px;
    left: 9px;
}

#index3{
    opacity: 0;
    top: 15px;
    left: 20px;
    text-align: end;
    width: 90px;
}

.regenerative {
    top: -25px;
    right: 82px;
}
.regenerative::after  {
    transform: rotate(12deg);
    top: 23.8px;
    left: 9px;
}

#index0{
    opacity: 0;
    top: -25px;
    left: 5px;
    text-align: center;
    width: 135px;
}

.radar-icon.preserve {
    right: -18px;
    top: 61px;
}
.radar-icon.preserve::after {
    transform: rotate(-118deg);
    top: 14px;
    left: -5px;
}

#index1{
    opacity: 0;
    top: -50px;
    width: 130px;
    left: -35px;
    text-align: center;
}

.radar-icon.waste {
    right: -20px;
    top: 190px;
}
.radar-icon.waste::after {
    transform: rotate(-63deg);
    top: -2.2px;
    left: -5px;
}

#index2{
    opacity: 0;
    top: -40px;
    width: 90px;
    text-align: end;
    right: -60px;
}

.radar-icon.collaborate {
    left: -30px;
    top: 125px;
}
.radar-icon.collaborate::after {
    transform: rotate(90deg);
    top: 5.4px;
    left: 32.6px;
}

#index5{
    opacity: 0;
    top: 35px;
    text-align: center;
    width: 90px;
    right: -15px;
}

.radar-icon.digital {
    top: 10px;
    left: 26px;
}
.radar-icon.digital::after {
    transform: rotate(-38deg);
    top: 20px;
    left: 24.4px;
}

#index6{
    opacity: 0;
    top: -25px;
    width: 120px;
    left: -110px;
}

.radar-icon.design {
    top: 239px;
    left: 27px;
}
.radar-icon.design::after {
    transform: rotate(39deg);
    top: -9px;
    left: 25px;
}

#index4{
    opacity: 0;
    top: 20px;
    text-align: start;
    width: 90px;
    right: -15px;
}

.chartjs-render-monitor {
    user-select: none;
}

canvas.chartjs-render-monitor {
    position: relative;z-index: 99;
}

#chartjs-tooltip{
    height: 60px;
    position: absolute;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #efefefb3;
    min-width: 90px;
    pointer-events: none;
    box-shadow: 0px 4px 14px rgba(51, 113, 110, 0.25);
    border-radius: 3px;
}

.img{
    width: 25px;
    height: 25px;
    margin: 0 5px;
}

.row{
    display: flex;
    align-items: center;
}

.is-mobile #index1 {
    left: -72px;
}
.is-mobile #index2 {
    top: -55px;
    right: -32px;
}
.is-mobile #index5 {
    right: -35px;
}
.is-mobile #index6 {
    left: -80px;
}