.info-pane-wrapper {
    /* padding: 10px 15px; */
    z-index: 998;
    overflow-y: auto!important;
    height: 100%;
    width: 460px;
    border-radius: 0!important;
}
.button-row {
    display: flex;
    justify-content: space-around;
}
.info-pane-wrapper button {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 10px!important;
    font-weight: 600!important;
    width: 140px;
}
.info-pane-wrapper button.map-scope-button,
.info-pane-wrapper button.show-on-map-button {
    box-shadow: none;
    background-color: #EAEAEA;
}
.info-pane-wrapper button span.polygon-triangle_left {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #FFFFFF;
    margin-right: 5px;
}

.info-pane-wrapper .chart-wrapper .chart-margin {
    width: 260px;
    height: 260px;
    margin: 45px auto;
    margin-bottom: 60px;
}
.is-mobile .chart-margin {
    margin: 75px auto!important;
    width: 240px!important;
    height: 240px!important;
}

.chart-legend{
    padding: 20px 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
}

.legend-block{
    display: flex;
    height: 25px;
    align-items: center;
    margin: 0 5px;
}

.legend-block-center:only-child {
    margin: 0 auto;
}

.legend-block img{
    width: 25px;
    margin-right: 5px;
}

.legend-block .legend-label{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10.44px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2D2D2D;
}


.info-pane_header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.info-pane_header .province {
    padding: 25px 0;
}
.province .label {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 11px;
    color: #A5A5A5;
    padding: 5px 0;
}
.province .province-name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #263238;
    letter-spacing: 2px;
}
.province .compare {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #646464;
    position: relative;
}
.compare .compare-item {
    font-weight: 700;
}
.compare:not(.disabled) .compare-item {
    cursor: pointer;
}
.compare .polygon-triangle_bottom {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #C4C4C4;
    position: absolute;
    top: 4px;
}

.jobs-info-wrapper .wrapper {
    width: 100%;
}

.jobs-info-wrapper .label {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #475662;
    margin-top: 25px;
}

table.info-table {
    width: 94%;
}
table.info-table span.bold {
    font-weight: 700;
    text-transform: capitalize;
}
table.info-table span.bold.blue {
    color: #2879B9;
}
table.info-table tr>td {
    padding: 2px 6px;
    color: #686868;
}
table.info-table tr>td:first-child {
    text-align: right;
}

.compare-tooltip {
    right: 0;
    z-index: 999;
}
.compare-list-wrapper {
   padding: 7px 6px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   z-index: 1000;
}
.compare-list-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 26px;
    font-weight: normal;
    color: #000000;
    text-align: left;
    cursor: pointer;
}
.compare-list-item:hover {
    text-decoration: underline;
}
.compare-item_name {
    font-weight: bold;
}

.compare.disabled {
    opacity: .5;
}

.jobs-info-wrapper_print {
    display: none;
}

.circular-total-percent{
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular-total-percent .percent{
    padding: 10px;
    background: #7CE3C5;
    border-radius: 5px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

.circular-total .jobs{
    display: flex;
    padding: 2px 0 2px 10px;
}

.circular-total .jobs:first-child{
    border-bottom: 1px solid #CCCCCC;
}

.circular-total .jobs .jobs-label{
    margin-left: 10px;
}

.circular-total .jobs .jobs-count{
    min-width: 65px;
    text-align: right;
}

.circular-total .jobs:first-child{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #263238;
}

.circular-total .jobs{
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    color: #263238;
}
















.info-table .wrapper-block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 2px solid #E8E8E8;
}

.info-table .wrapper-block:last-child{
    border-bottom: none;
}

.info-table .wrapper-block .element-block{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    align-items: flex-start;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #686868;
    box-sizing: border-box;
}

.info-table .wrapper-block .element-block .element-visible{
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #3e464c;
    box-sizing: border-box;
    cursor: pointer;
    padding-top: 0;
    min-height: 55px;
}

.bold{
    font-weight: bold;
}

.blue{
    text-transform: uppercase;
    color: #2879B9;
}


.info-table .wrapper-block .element-block:not(:first-child){
    border-top: 1px solid #F3F3F3;
}


.block-name{
    text-align: start;
    width: 100%;
    font-size: 13px;
}

.block-total{
    margin: 0 5px;
    width: 110px;
    color: #000000;
}

.block-percent{
    width: 60px;
    flex-shrink: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #6FC9AF;
}

.block-percent.padding{
    background: #7CE3C5;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 5px;
    box-sizing: border-box;
}

.datatable-wrapper{
    text-align: start;
    margin-left: 40px;
    margin-top: 30px;
    width: calc(100% - 40px);
    padding: 10px 5px;
    box-sizing: border-box;
    background: rgba(155, 155, 155, 0.07);
}







.initiative-block{
    display: flex;
    padding: 30px 15px;
}

.initiative-img img{
    width: 145px;
}

.initiative-content{
    text-align: start;
    margin-left: 25px;
}

.initiative-title{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #2879B9;
}

.initiative-type{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    line-height: 11px;
    color: #6B6B6B;
}

.initiative-description{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
    margin: 15px 0;
}

.initiative-readmore{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 10px;
    color: #959595;
}

.initiative-wrapper{
    display: none;
}

.block-icon{
    margin-right: 10px;
    width: 15px;
}

.block-icon img{
    width: 8px;
    height: 8px;
}


.legend-select{
    position: relative;
}

.compare-list-wrapper {
    position: absolute;
    right: -55px;
    top: 30px;
    width: 285px;
    background-color: rgba(255, 255, 255, 0.95);
    color: #393939;
    -webkit-box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    0 1px 3px 0 rgba(50, 50, 50, 0.75);
    box-shadow:         0 1px 3px 0 rgba(50, 50, 50, 0.75);
}

nav.compare-list {
    max-height: 240px;
    overflow-y: auto;
}
li.compare-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #393939;
    font-weight: 700;

    cursor: pointer;
}
li.compare-item:hover {
    background-color: rgba(0, 0, 0, .1);
}
li.compare-item.active {
    background-color: rgba(0, 0, 0, .1);
}
li.compare-item span.type {
    font-weight: 400;
    font-style: italic;
}

.compare-item-margin{
    padding-left: 10px;
}

.parents{
    border-bottom: 2px #dddddd80 solid;
}

.input-compare input{
    padding: 6px;
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10.44px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2D2D2D;
}

.list-item-text{
    /* font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #3a3839;
    font-weight: 700;
    cursor: pointer; */
}

.parents-scopes:first-child{
    background-color: transparent !important;
}


.info-pane-block{
    padding: 10px 15px;
}
.info-pane-block.not-selected {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #3E464C;
    top: 50%;
    position: absolute;
    width: 100%;
}
.info-pane-outer-wrapper.print .info-pane-block.not-selected {
    top: 90px;
}
.info-pane-outer-wrapper.print .info-pane-block {
    padding: 0;
}

.info-pane-block .share-link-wrapper {
    cursor: pointer;
}
.no-print .wrapper{
    margin-bottom: 25px;
}

.divider{
    height: 1px;
    background-color: #dcdcdc;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.CTCJV, .DFTF, .IDT, .PAEWAM, .PRR, .RTBM, .UWAAR, .IDRC {
    height: 53px;
    overflow: hidden;
    -moz-transition: height .3s;
    -ms-transition: height .3s;
    -o-transition: height .3s;
    -webkit-transition: height .3s;
    transition: height .3s;
}

.IDRC .element-visible{
    line-height: 30px !important;
}

.hide {
    display: none;
}

@media screen and (max-width: 440px) {
    .info-pane-wrapper {
        width: 335px;
    }
    .block-icon {
        margin-right: 5px;
    }
    .block-name {
        width: 115px;
        text-overflow: ellipsis;
/*         white-space: nowrap;
        overflow: hidden; */
        font-size: 11px;
    }
}


.info-pane-wrapper.is-mobile {
    width: 100%;
}
.info-pane-wrapper.is-mobile .info-pane_header {
    text-align: center;
}

.info-pane-wrapper.is-mobile .datatable-wrapper {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
}


@media screen and (max-width: 374px) {
    .info-table .wrapper-block .element-block .element-visible{
        line-height: 12px;
    }
    
    .block-name {
        font-size: 10px;
    }
    .block-total {
        font-size: 12px;
    }

    .circular-total-percent .percent {
        font-size: 18px;
    }
    .circular-total .jobs {
        font-size: 14px!important;
    }

    .is-mobile .chart-margin {
        width: 220px!important;
        height: 220px!important;
    }

    .radar-icon.rethink {
        top: 245px!important;
        right: 72px!important;
    }

    .radar-icon.design {
        top: 220px!important;
        left: 20px!important;
    }

    .radar-icon.waste {
        right: -20px!important;
        top: 172px!important;
    }

    .radar-icon.collaborate {
        top: 110px!important;
    }

    .is-mobile #index6 {
        left: -60px!important;
        top: -40px!important;
    }
    .is-mobile #index1 {
        width: 110px!important;
        left: -70px!important;
        top: -60px!important;
    }
    .is-mobile #index2 {
        right: -15px!important;
        top: 55px!important;
    }


}


.close-wrapper>div {
    cursor: pointer;
}

.print-wrapper>div {
    cursor: pointer;
}

.subtitle-print {
    display: none;
}


.share-print-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    padding: 10px 10px 0 0;
}

.share-print-wrapper svg {
    fill: #656565;
}


.share-print-wrapper svg:hover {
    fill: #3c7db7
}
