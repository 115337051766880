.info-pane-wrapper_mobile {
    
}
.info-pane-wrapper_dialog .close-button { 
    position: absolute;
    top: 10px;
    right: 14px;
    width: 36px;
    height: 36px;
    background-color: #FFF;
    border-radius: 50%;
    z-index: 9999;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
}
.mcb {
    height: 16px;
    width: 2px;
    margin-left: 17px;
    background-color: #263238;
    transform: rotate(45deg);
    border-radius: 2px;
    z-index: 1;
}
  
.cb {
    height: 16px;
    width: 2px;
    margin-top: 9px;
    background-color: #263238;
    transform: rotate(90deg);
    border-radius: 2px;
    z-index: 2;
}

.info-pane-wrapper_dialog  div[role="dialog"] {
    bottom: 0;
    position: absolute;
    margin: 0!important;
}
.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 68px)!important;
}