header.header{
    position: relative;
    background-color: #3876B7;
    border-bottom: 1px solid rgba(0, 0, 0, .75);
    z-index: 9999;
}

.title-wrapper {
    font-family: 'Montserrat';
    font-style: 'Regular, Bold';
    text-align: left;
}

.title-wrapper p.title{
    font-weight: bold;
    font-size: 18px;
}

.title-wrapper p.subtitle{
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 25px;
}
.header-toolbar {
    display: flex;
    justify-content: space-between;
    padding-right: 0!important;
    min-height: 0!important;
    height: 60px;
}
.search {
    background-color: rgba(238, 238, 241, 0.2);
    border-radius: 4px;
    position: relative;
    width: 300px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    position: relative;
}
.search .searchIcon {
    display: flex;
    position: absolute;
    right: 5px;
    color: #FFFFFF;
    opacity: .3;
}
.search input {
    padding: 5px 10px;
    color: #FFFFFF;
}

/* .search {
    position: relative;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, .15);
    margin-right: 20px;
    margin-left: 0;
    width: 100%;
} */

.search-list-wrapper {
    position: absolute;
    right: 5px;
    top: 30px;
    width: calc(100% - 10px);
    background-color: rgba(255, 255, 255, 0.95);
    color: #393939;
    z-index: 9999;

    -webkit-box-shadow: 0px 1px 3px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    0px 1px 3px 0px rgba(50, 50, 50, 0.75);
    box-shadow:         0px 1px 3px 0px rgba(50, 50, 50, 0.75);
}

nav.search-list {
    max-height: 240px;
    overflow-y: auto;
}
li.search-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #393939;
    font-weight: 700;

    cursor: pointer;
}
li.search-item:hover {
    background-color: rgba(0, 0, 0, .1);
}
li.search-item.active {
    background-color: rgba(0, 0, 0, .1);
}
li.search-item span.type {
    font-weight: 400;
    font-style: italic;
}
.flex-right {
    display: flex;
    padding-right: 20px;
}
.info-wrapper,
.download-wrapper {
    padding: 0 10px;
    margin-right: 30px;

    position: relative;
    bottom: -5px;
}
.info-wrapper {
    margin-right: 15px;
}
.info-wrapper>div,
.download-wrapper>div {
    cursor: pointer;
}

.has-label:hover {
    position: relative;
}

*[aria-label]:hover:after {
    content: attr(aria-label);
    padding: 4px 6px;
    position: absolute;
    left: 0;
    top: 100%;
    white-space: nowrap;
    z-index: 9999;
    background:#37474fd9;
    border-radius: 4px;
    font-size: 10px;
    color: #FFF;
}

.info-wrapper .button {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    top: -5px;
    color: #FFFCFC;
}


@media screen and (max-width: 1025px) {
    .title-wrapper p.subtitle{
        font-size: 16px;
    }
}

@media screen and (max-width: 440px) {
    .title-wrapper p.subtitle {
        font-size: 12px;
    }

    .search {
        width: 170px;
    }

    .flex-right svg{
        width: 18px !important;
        height: 18px !important;
    }

}