.info-popup-wrapper_mobile .close-button { 
    position: absolute;
    top: -40px;
    right: 0;
    width: 35px;
    height: 35px;
    background-color: #FCFCFC;
    border-radius: 50%;
}
.mcb {
    height: 16px;
    width: 2px;
    margin-left: 17px;
    background-color: #263238;
    transform: rotate(45deg);
    border-radius: 2px;
    z-index: 1;
}
  
.cb {
    height: 16px;
    width: 2px;
    margin-top: 9px;
    background-color: #263238;
    transform: rotate(90deg);
    border-radius: 2px;
    z-index: 2;
}

.popup-content {
    padding: 10px 20px;
    min-height: 60px;
    border-radius: 10px 10px 0 0!important;
}

.selected-place-wrapper {
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 1.5;
    min-height: 45px;
}
.selected-place-wrapper .name {
    color: #246DAF;
    font-weight: bold;

}
.selected-place-wrapper .parent-name {
    color: #6B6B6B;
    font-size: 14px;
}

.decorative-line {
    position: absolute;
    height: 2px;
    width: 36px;
    background-color: #C4C4C4;
    top: 4px;
    left: calc(50% - 18px);
}

.jobs-info-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.5;
}
.jobs-info-wrapper .block-svg {
    height: 24px;
}
.jobs-info-wrapper .jobs-title,
.jobs-info-wrapper .jobs-data {
    flex-basis: 50%;
    text-align: left;
    font-size: 13px;
    color: #1A1A1A;
    opacity: 0.8;
}
.jobs-info-wrapper .jobs-data {
    font-size: 14px;
    padding-left: 40px
}


@media screen and (max-width: 374px) {
    .jobs-info-wrapper .jobs-data {
        font-size: 12px;
        padding-left: 20px;
    }
}