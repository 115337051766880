.filter-controls-wrapper {
    display: flex;
    margin-top: 10px;
}
.filter-controls-wrapper>.view-data-type-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}
/* .job-types-wrapper {
    display: flex;
    padding: 0 30px;
    padding-right: 0;
} */
.total,
.core,
.enabling{
    padding: 0 2px;
}



/* start css for filter CJ v2 */

.filter-wrapper {
    display: flex;
    position: absolute;
    left: calc(50% - 400px);
    margin-top: 12px;
    border-radius: 8px!important;
    background-color: rgba(255, 255, 255, 0.95)!important;
    z-index: 999;
    overflow: unset!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    align-items: center;
}
.filter-wrapper:not(.collapse) {
    max-width: calc(100vw - 500px);
}

.filter-block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px 5px;
    cursor: pointer;
    min-height: 73px;
    max-height: 73px;
    box-sizing: border-box;
}

.filter-block.jobs-filter {
    min-width: 231px;
}

.filter-block.is-active{
    background-color: #2879B9;
}

.filter-block.is-active .middle-block{
    color: #FFFFFF;;
}

.filter-block:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}


.filter-block-header{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
}

.filter-block-content {
    min-height: 15px;
}
.filter-block-content .label{
    max-width: 155px;
    word-break: break-word;
    overflow: hidden;
    line-height: 12px;
}

.filter-block-content label>span{
   padding: 5px 10px;
}
.middle-block{
    padding: 8px 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #246DAF;
    display: flex;
    text-align: start;
    align-items: center;
}

.filter-block-content .label-filter{
    font-weight: normal;
    max-width: 155px;
    word-break: break-word;
    overflow: hidden;
    line-height: 12px;
}

.filter-item{
    display: flex;
    align-items: center;
    font-family: Montserrat;
}

.filter-block-icon div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.colorby{
    color: rgb(101, 101, 101);
    font-weight: bold;
    font-family: Montserrat;
    font-size: 12px;
}

/* finish css for filter CJ v2 */


.job-types-wrapper button {
    border-radius: 2px;
    font-weight: 900;
    font-size: 11px;
    background-color: #FFFFFF;
    color: #6B6B6B;
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.job-types-wrapper button.icons {
    padding: 0;
}
.button-event-area {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
}

.job-types-wrapper button .icon {
    padding: 0 6px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.job-types-wrapper button .icon:first-child {
    margin-left: 10px;
}

.filter-group>button>span {
    height: inherit;
}

.job-types-wrapper .top-ten-wrapper {
    margin: 0 8px 0 25px;
}

.scope-tooltip {
    z-index: 9999;
    position: fixed!important;
    max-height: calc(100% - 170px);
    overflow: auto;
    box-shadow: 0 0 8px 1px rgba(181, 181, 181, 0.25);
}

.scope-list-wrapper {
    padding: 5px 25px 5px 10px;
}
.scope-list-item {
    font-size: 15px;
    line-height: 27px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    text-align: left;
}
.scope-list-item:hover {
    text-decoration: underline;
    cursor: pointer;
}




button.icons .icon svg {
    fill: #A0A8B1!important;
}
.job-types-wrapper button:active,
.job-types-wrapper button:hover {
    background-color: #FAFAFA;
    color: #6B6B6B;
}

.job-types-wrapper button.active {
    background-color: #3876B7;
    color: #FAFAFA;
}

button.icons .icon.active {
    background-color: #3876B7;
}

button.icons .icon.active svg {
    fill: #FAFAFA!important;
}

button.icons .icon:active:not(.active) svg,
button.icons .icon:hover:not(.active) svg {
    fill: #3876B7!important;
}
button.icons .icon:not(.active) {
    background-color: #FAFAFA;
}

@keyframes show {
    from {opacity: 0;}
    to {opacity: 1;}
}

.arrow-pointing:after {
    display: block;
    content: '▾';
    padding: 0 2px;
    line-height: 14px;
}

/* .arrow-pointing.show:after {
    content: '▾';
} */

.filter-wrapper::-webkit-scrollbar {
    height: 4px;
}
.filter-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(169, 169, 169, 0.3);
    background-color: #F5F5F5;
    border-radius: 6px;
}
  
.filter-wrapper::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #A8A8A8;
}

img.arrow-down{
    width: 10px;
    margin-left: 5px;
}

.filter-block:first-child, .filter-block:last-child{
    min-width: 120px;
}
.filter-block.top-5{
    min-width: 144px;
}
.middle-block{
    min-width: 130px;
}

.middle-block-maplevel{
    min-width: 85px;
}

.middle-block-filter{
    min-width: 145px;
}
@media screen and (max-width: 1740px) {
    .filter-wrapper:not(.collapse) {
        left: calc(50vw - 620px)
    }
}

@media screen and (max-width: 1300px) {
    .filter-wrapper {
        max-width: calc(100% - 120px);
        overflow-x: scroll!important;
        overflow-y: hidden!important;
    }
    .filter-wrapper:not(.collapse) {
        left: 10px
    }
}

@media screen and (max-width: 800px) {
    .filter-wrapper {
        left: 55px;
    }
}

.is-mobile .filter-wrapper {
    max-width: calc(100% - 20px);
    left: 10px;
    border-radius: 8px 8px 0 0!important;
}
.is-mobile .filter-content,
.is-mobile .mapleve,
.is-mobile .scope-list,
.is-mobile .footer,
.is-mobile .scope-content {
    padding: 20px 15px;
}
.is-mobile .filter-block {
    padding: 10px 10px 5px;
    min-width: auto;
    min-height: 63px;
    max-height: 63px;
    box-sizing: border-box;
}
.is-mobile .filter-block-content {
    white-space: nowrap;
}

.is-mobile .label-filter {
    text-overflow: ellipsis;
}

.is-mobile .scope-tooltip {
    top: 146px!important;
    width: calc(100% - 20px);
    left: 10px!important;
    border-radius: 8px;
}
.is-mobile .middle-block{
    min-width: 80px;
}
.is-mobile .filter-radio {
    padding: 4px 10px 4px 6px!important;
}

.is-mobile .scope-list {
    overflow-x: auto;
    width: calc(100% - 75px);
}

.scope-tooltip::-webkit-scrollbar {
    width: 4px;
}
.scope-tooltip::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(169, 169, 169, 0.3);
    background-color: #F5F5F5;
    border-radius: 6px;
}
  
.scope-tooltip::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #A8A8A8;
}