.dialog {
    z-index: 9999!important;
}

.methodology-explanation-dialog{
    padding: 0 !important;
    overflow: hidden !important;
    font-size: 15px;
    line-height: 18px;
    color: #292929;
    height: 100vh;
    width: 959px;
}

div.MuiDialog-paper {
    margin: 0;
}

div.MuiDialog-container.MuiDialog-scrollPaper {
    align-items: flex-start;
    justify-content: flex-end;
    height: 100vh;
    max-height: 100vh;
}

div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
    max-height: 100vh !important;
    border-radius: 0;
}

.MuiDialog-paper{
    overflow: hidden !important;
}

.box{
    height: 100%;
    padding: 0 !important;
}

.close-btn{
    position: absolute;
    right: 20px;
    bottom: 14px;
    cursor: pointer;
    padding: 3px 6px;
    height: 36px;
}


.scroll-btn{
    display: block;
    cursor: pointer;
    margin-right: 8px;
    float: right;
    width: 36px;
    height: 36px;
}

@media screen and (max-width: 1025px) {
}

span.MuiTab-wrapper {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: flex-start;
    text-align: left;
    text-transform: none;
    width: 331px;
}

div.MuiTabs-flexContainer.MuiTabs-flexContainerVertical{
    margin: 0;
    margin-top: 60px;
    width: 331px;
}


button.MuiTab-root {
    padding: 0;
    padding-left: 20px;
    height: 41px;
    max-width: 100%;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;

    color: rgba(38, 50, 56, 1);
    opacity: 1;
}

button.MuiTab-root.heading-tab {
    font-weight: bold;
    font-size: 13px;
    line-height: 30px;
    text-transform: uppercase;
    color: rgba(17, 135, 179, 1);
    opacity: 1;
}

button.MuiTab-root.heading-tab span.MuiTab-wrapper {
    text-transform: uppercase;
}

button.MuiTab-root.active {
    background: #1187B3;
    color: #FFF;
}

div.MuiTabs-root {
    border-right: 1px solid #C4C4C4;
}

div.MuiBox-root {
    margin-top: 60px;
    width: 100%;
    
}

h1.MuiTypography-h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #263238;
    opacity: 1;
}

h1.MuiTypography-h1.special {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
}

h1.MuiTypography-h1.bottom {
    font-weight: bold;
}

h2.MuiTypography-h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #263238;
    opacity: 1;
    margin-top: 45px;

}

p.MuiTypography-body1, li {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #474747;
    opacity: 1;
}

p.MuiTypography-body1 span.highlight {
    color: #1187B3;
    cursor: pointer;
}

p.MuiTypography-body1 span.highlight:hover {
    text-decoration: underline;
}


span.MuiTypography-button {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: none;
    color: #1187B3;
    opacity: 1;
}

ul {
    padding-left: 25px;
    margin-top: 0;
}

div.MuiBox-root.tab-panel {
    margin-left: 53px;
    width: 508px;
    max-width: 508px;
}

/* @media screen and (max-width: 1025px) {
    
} */
div[role=tabpanel]{
    height: 100vh;
    padding-right: 64px;
    overflow: auto !important;
}

@media screen and (max-width: 680px){
    span.MuiTab-wrapper {
        width: 397px;
    }
    
    div.MuiTabs-flexContainer.MuiTabs-flexContainerVertical{
        width: 397px;
    }

    div.MuiTabs-flexContainer.MuiTabs-flexContainerVertical{
        margin-top: 7px;
    }

    div.MuiBox-root.tab-panel {
        width: 312px;
        max-width: 397px;
        margin-left: 0px;
    }

    div.MuiDialogContent-root.methodology-explanation-dialog {
        overflow-y: auto !important;
        width: 397px;
    }

    div[role=tabpanel]{
        overflow: hidden !important;
        max-width: 397px;
        width: 397px;
        border-bottom: 1px solid #C4C4C4;
        height: auto;
        margin-left: 20px;
    }

    div.MuiTabs-root {
        border-right: none;
        border-bottom: 1px solid #C4C4C4;
    }

    div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
        margin-left: 18px;
    }

    p.MuiTypography-body1 {
        width: 312px;
    }

    li {
        width: 287px;
    }

}