@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700,700i);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,800);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
  line-height: 1.2;
}

* {
  font-family: 'Open Sans', sans-serif;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

#cookiescript_badge {
  opacity: 0.3;
}

#cookiescript_badge:hover {
  opacity: 0.8;
}

@media print {
  .no-print { display: none!important; }

  .info-pane-outer-wrapper {
    display: inline-block!important;
  }
  .leaflet-control-container {
    display: none!important;
  }

  .legend-wrapper {
    bottom: 15px!important;
    right: 15px!important;
  }

  .map-wrapper {
    display: flex!important;
    flex-direction: column!important;
    height: 100vh!important;
    width: auto!important;
    box-shadow: none!important;
    margin: 0!important;
    padding: 0!important;
  }

  .map-components-wrapper {
    width: auto!important;
    margin-bottom: 10px!important;
  }

  .map-components-wrapper:after {
    content: '';
  }

  .info-pane-outer-wrapper {
    position: unset!important;
    box-shadow: none!important;
    height: 52%!important;

    border-top: 1px solid #CACACA!important;
    padding-top: 10px!important;
  }
  .map-components-wrapper {
    height: calc(42% - 21px)!important;
  }
  .leaflet-container {
    height: 100%!important;
  }

  .info-pane-wrapper {
    width: auto!important;
    position: relative!important;
    height: 100%!important;
    box-shadow: none!important;
    padding: 0!important;
  }
  .info-pane_header {
    position: absolute!important;
    width: 50%!important;
    left: 0!important;

    display: flex!important;
    justify-content: flex-start!important;
  }
  .info-pane_header .province{
    padding: 25px 0 15px!important;
  }
  .info-pane_header .label {
    color: #000!important;
    font-family: 'Montserrat';
    font-weight: 300!important;
    font-size: 14px!important;
    text-align: left!important;
  }
  .info-pane_header .province-name {
    color: #2D2D2D!important;
    text-transform: uppercase!important;
    font-family: 'Montserrat';
    font-weight: 500!important;
    font-size: 24px!important;
    text-align: left!important;
    letter-spacing: 0.1em!important;
  }
  .chart-wrapper {
    position: absolute!important;
    right: 0!important;
    padding: 30px calc(25% - 125px)!important;
    margin: 0!important;
  }
  .jobs-info-wrapper_print {
    display: flex!important;
    justify-content: space-between!important;
    width: 100%!important;
    position: absolute!important;
    bottom: 10px!important;
    padding-bottom: 20px!important;
  }

  .jobs-info-wrapper_print .left {
    flex: 1 1!important;
  }
  .total_print {
    text-align: left!important
  }
  .jobs-info-wrapper_print .right {
    flex: 1 1!important;
    padding-top: 176px!important;
  }
  .info-table.top {
    margin: 40px 0 60px 0!important;
  }
  .info-table.top td{
    font-size: 16px;
  }
  .info-table.top .tc {
    font-size: 17px!important;
    padding: 0!important;
  }
  .info-table tr {
    height: 38px!important;
  }
  .tc {
    text-align: left!important;
    min-width: 160px!important;
  }

  td.td-icon {
    width: 25px!important;
  }
  td.td-percent {
    width: 25px!important;
  }

  .info-table.borderless td,
  tbody>tr:last-child>td {
    border: none!important;
  }
  .bold {
    font-weight: bold!important;
  }

  .print-header {
    background-color: #FFF!important;
    color: #2D2D2D;
    height: 6%!important;
    font-family: 'Montserrat'!important;
    text-align: left!important;

    display: flex!important;
    flex-direction: column!important;
    justify-content: space-between!important;
    padding: 10px 0px!important;
    padding-bottom: 1px!important;
  }
  .print-header>.subtitle {
    display: block!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: normal!important;
    font-size: 22px!important;
    letter-spacing: 0.1em!important;
    text-transform: uppercase!important;
    color: #2D2D2D!important;
  }
  .print-header>.subtitle-print {
    display: block!important;
    font-size: 12px!important;
    color: #000!important;
  }

  div[aria-label]:hover:after {
    display: none!important;
  }
}

@media screen and (max-width: 440px) {
  .leaflet-top, .leaflet-bottom {
    z-index: 997 !important;
  }
}

@media print and (color) {
  * {
     -webkit-print-color-adjust: exact;
     print-color-adjust: exact;
  }
}

.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header.header{
    position: relative;
    background-color: #3876B7;
    border-bottom: 1px solid rgba(0, 0, 0, .75);
    z-index: 9999;
}

.title-wrapper {
    font-family: 'Montserrat';
    font-style: 'Regular, Bold';
    text-align: left;
}

.title-wrapper p.title{
    font-weight: bold;
    font-size: 18px;
}

.title-wrapper p.subtitle{
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 25px;
}
.header-toolbar {
    display: flex;
    justify-content: space-between;
    padding-right: 0!important;
    min-height: 0!important;
    height: 60px;
}
.search {
    background-color: rgba(238, 238, 241, 0.2);
    border-radius: 4px;
    position: relative;
    width: 300px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    position: relative;
}
.search .searchIcon {
    display: flex;
    position: absolute;
    right: 5px;
    color: #FFFFFF;
    opacity: .3;
}
.search input {
    padding: 5px 10px;
    color: #FFFFFF;
}

/* .search {
    position: relative;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, .15);
    margin-right: 20px;
    margin-left: 0;
    width: 100%;
} */

.search-list-wrapper {
    position: absolute;
    right: 5px;
    top: 30px;
    width: calc(100% - 10px);
    background-color: rgba(255, 255, 255, 0.95);
    color: #393939;
    z-index: 9999;
    box-shadow:         0px 1px 3px 0px rgba(50, 50, 50, 0.75);
}

nav.search-list {
    max-height: 240px;
    overflow-y: auto;
}
li.search-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #393939;
    font-weight: 700;

    cursor: pointer;
}
li.search-item:hover {
    background-color: rgba(0, 0, 0, .1);
}
li.search-item.active {
    background-color: rgba(0, 0, 0, .1);
}
li.search-item span.type {
    font-weight: 400;
    font-style: italic;
}
.flex-right {
    display: flex;
    padding-right: 20px;
}
.info-wrapper,
.download-wrapper {
    padding: 0 10px;
    margin-right: 30px;

    position: relative;
    bottom: -5px;
}
.info-wrapper {
    margin-right: 15px;
}
.info-wrapper>div,
.download-wrapper>div {
    cursor: pointer;
}

.has-label:hover {
    position: relative;
}

*[aria-label]:hover:after {
    content: attr(aria-label);
    padding: 4px 6px;
    position: absolute;
    left: 0;
    top: 100%;
    white-space: nowrap;
    z-index: 9999;
    background:#37474fd9;
    border-radius: 4px;
    font-size: 10px;
    color: #FFF;
}

.info-wrapper .button {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    top: -5px;
    color: #FFFCFC;
}


@media screen and (max-width: 1025px) {
    .title-wrapper p.subtitle{
        font-size: 16px;
    }
}

@media screen and (max-width: 440px) {
    .title-wrapper p.subtitle {
        font-size: 12px;
    }

    .search {
        width: 170px;
    }

    .flex-right svg{
        width: 18px !important;
        height: 18px !important;
    }

}
.map-wrapper {
    position: relative;
}

.leaflet-container {
    height: calc(100vh - 61px);
    width: 100%;
    margin: 0 auto;
}

.hover-balloon {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}
 .title-name {
    font-weight: 700!important;
    font-family: 'Montserrat', sans-serif;
     font-size: 14px;
     color: #006989;
}
.hover-balloon .subtitle-name {
    font-size: 11px;
}
.hover-balloon .jobs {
    font-weight: bold;
    padding-top: 20px;
}
.leaflet-popup-close-button {
    display: none;
}
.leaflet-popup.leaflet-zoom-animated {
    pointer-events: none;
}

.leaflet-marker-icon {
    background: unset!important;
    border: none!important;
    margin-left: -12px!important;
}
.leaflet-marker-icon .top-five-marker,
.leaflet-marker-icon .pin-marker {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #D75E3B;
    position: relative;
    transform: rotate(-45deg);
    margin: -20px 0 0 -5px;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
    box-shadow: -1px 1px 1px 1px rgba(41, 41, 41, 0.62);
}
.leaflet-marker-icon .top-five-marker:after {
    content: '';
    width: 18px;
    height: 18px;
    margin: -12px 0 0 -9px;
    background: #FFF;
    position: absolute;
    border-radius: 50%;
}
.top-five-marker>.text {
    display: block;
    position: relative;
    transform: rotate(45deg);
    color: #D75E3B;
    font-weight: 700;
    top: 6px;
    left: 0;
    z-index: 2;
}

.leaflet-marker-icon .pin-marker {
  background: #343E44;
}
.leaflet-marker-icon .marker-color {
  position: absolute;
  content: '';
  width: 22px;
  height: 22px;
  background: inherit;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}

.map-components-wrapper {
    width: 100%;
    position: relative;
    float: left;
}
.map-components-wrapper.infopane {
  width: calc(100vw - 460px);
}
.info-pane-outer-wrapper {
    height: calc(100vh - 61px);
    display: inline-block;
    position: absolute;
    right: 0;
    box-shadow:         0 0 4px 0 rgba(0, 0, 0, 0.25);

    z-index: 997;
}

.collapse-button {
    width: 18px;
    height: 40px;
    background-color: #FFF;

    position: absolute;
    left: -19px;
    top: 50px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    z-index: 999;
    box-shadow:         -2px 0 4px 0 rgba(0, 0, 0, 0.25);
}
.collapse-button:after {
    content: '';
    position: absolute;
    top: calc(50% - 8px);
    right: calc(50% - 4px);

    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #888888;
}
.collapse-button.open:after {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent; 
    border-right: 8px solid #888888;
    border-left: none;
}

.print-header {
    display: none;
}

.map-components-wrapper.print {
    width: 210mm;
}
.map-components-wrapper.print #map {
    height: 52vh;
    min-height: 400px
}

.print-content-wrapper {
    position: absolute;
    left: 0;
    top: -130px;
    width: 240px;
}
.print-content-wrapper .print-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;

    padding: 15px 0;
}
.print-content-wrapper button {
    font-weight: 700;
    margin: 10px 10px 10px 0;
    color: #414141;
    font-size: 12px;
}
.print-content-wrapper .button-row {
    justify-content: flex-start;
}
button.print-button,
button.print-button:active,
button.print-button:hover {
    border-radius: 5px;
    background-color: #4273B5;
    color: #FFF;
}
.info-pane-outer-wrapper.print {
    display: none;
}


/* print */
.map-wrapper.print .no-print {
    display: none;
}
.map-wrapper.print {
    width: 210mm;
    height: 297mm;

    display: flex;
    flex-direction: column;
    margin: 146px 5px 5px 20px;
    padding: 25px;
    box-shadow:         1px 1px 5px 0 rgba(50, 50, 50, 0.75);
}
.map-wrapper.print .collapse-button {
    display: none;
}
.map-wrapper.print .print-header {
    display: block;
}
.map-wrapper.print .button-row.no-print {
    display: none!important;
}
.map-wrapper.print .jobs-info-wrapper_print{
    display: flex!important;
    justify-content: space-between!important;
    width: 100%!important;
    position: absolute!important;
    bottom: 0px!important;
    padding-bottom: 0px!important;
}

.map-wrapper .jobs-info-wrapper_print.hide {
  display: none!important;
}

.map-wrapper.print .jobs-info-wrapper {
    display: none;
}
.map-wrapper.print .info-pane-outer-wrapper {
    display: block;
    position: unset!important;
    box-shadow: none!important;
    height: 52%!important;

    border-top: 1px solid #CACACA!important;
    padding-top: 10px!important;
}
.map-wrapper.print .info-pane-wrapper {
    width: auto!important;
    position: relative!important;
    height: 100%!important;
    box-shadow: none!important;
    overflow: hidden!important;
    padding: 0;
}
.map-components-wrapper.print {
    width: 100%;
    height: calc(40% - 22px);
    margin-bottom: 10px;
}

.map-wrapper.print .print-header {
    background-color: #FFF!important;
    color: #2D2D2D;
    font-family: 'Montserrat', sans-serif !important;
    text-align: left!important;

    display: flex!important;
    flex-direction: column!important;
    justify-content: space-between!important;
    padding: 10px 0 1px 0;
    min-height: 42px;
}
.map-wrapper.print .print-header>.subtitle {
    display: block!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: normal!important;
    font-size: 22px!important;
    letter-spacing: 0.1em!important;
    text-transform: uppercase!important;
    color: #2D2D2D!important;
}
.map-wrapper.print .subtitle-print {
    display: block!important;
    font-size: 12px!important;
    color: #000!important;
    text-align: left;
    padding: 2px 0 4px;
}

.map-wrapper.print .jobs-info-wrapper_print .left {
    flex: 1 1!important;
  }
.map-wrapper.print .total_print {
    text-align: left!important
}
.map-wrapper.print .jobs-info-wrapper_print .right {
    flex: 1 1!important;
    padding-top: 176px!important;
}
.map-wrapper.print .info-table.top {
    margin: 40px 0 60px 0!important;
}
.map-wrapper.print .info-table.top .tc {
    font-size: 17px!important;
    padding: 0;
}
.map-wrapper.print .info-table tr {
    height: 38px!important;
}
.map-wrapper.print .tc {
    text-align: left!important;
    min-width: 160px!important;
}

.map-wrapper.print td.td-icon {
    width: 25px!important;
}
.map-wrapper.print td.td-percent {
    width: 25px!important;
}
.map-wrapper.print table.info-table.top td {
    font-size: 16px;
}
.map-wrapper.print table.info-table.top td.td-percent {
    font-weight: bold;
    color: #2879B9;
}
.map-wrapper.print .chart-wrapper {
    position: absolute!important;
    right: 0!important;
    padding: 0px calc(25% - 125px)!important;
    margin: 0!important;
    top: -10px!important;
}

.map-wrapper.print .info-pane_header {
    position: absolute!important;
    width: 50%!important;
    left: 0!important;

    display: flex!important;
    justify-content: flex-start!important;

}

.map-components-wrapper.print #map {
    height: 100%;
}

.map-wrapper.print .info-pane_header .label {
    color: #000!important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300!important;
    font-size: 14px!important;
    text-align: left!important;
  }
  .map-wrapper.print .info-pane_header .province-name {
    color: #2D2D2D!important;
    text-transform: uppercase!important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500!important;
    font-size: 24px!important;
    text-align: left!important;
    letter-spacing: 0.1em!important;
  }


  .map-wrapper.print .info-table.borderless td,
  .map-wrapper.print tbody>tr:last-child>td {
    border: none;
  }
  
  .leaflet-control-zoom-in:after, .leaflet-control-zoom-out:after {
    opacity: 0;
  }

  .loading{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 9999;
  }

  .loading .spinner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
  }

  .loading .spinner .spinner-text{
      margin-left: 25px;
      color: #909090;
      font-size: 21px;
      font-weight: 700;
  }

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #3776b7;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .leaflet-bottom {
      margin-bottom: 32px !important;
  }

  .info-popup-wrapper_mobile {
    position: absolute;
    bottom: 0;
    width: calc(100vw - 20px);
    box-sizing: border-box;
    margin: 0 10px;
    z-index: 999;
  }
.filter-controls-wrapper {
    display: flex;
    margin-top: 10px;
}
.filter-controls-wrapper>.view-data-type-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}
/* .job-types-wrapper {
    display: flex;
    padding: 0 30px;
    padding-right: 0;
} */
.total,
.core,
.enabling{
    padding: 0 2px;
}



/* start css for filter CJ v2 */

.filter-wrapper {
    display: flex;
    position: absolute;
    left: calc(50% - 400px);
    margin-top: 12px;
    border-radius: 8px!important;
    background-color: rgba(255, 255, 255, 0.95)!important;
    z-index: 999;
    overflow: unset!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    align-items: center;
}
.filter-wrapper:not(.collapse) {
    max-width: calc(100vw - 500px);
}

.filter-block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px 5px;
    cursor: pointer;
    min-height: 73px;
    max-height: 73px;
    box-sizing: border-box;
}

.filter-block.jobs-filter {
    min-width: 231px;
}

.filter-block.is-active{
    background-color: #2879B9;
}

.filter-block.is-active .middle-block{
    color: #FFFFFF;;
}

.filter-block:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}


.filter-block-header{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
}

.filter-block-content {
    min-height: 15px;
}
.filter-block-content .label{
    max-width: 155px;
    word-break: break-word;
    overflow: hidden;
    line-height: 12px;
}

.filter-block-content label>span{
   padding: 5px 10px;
}
.middle-block{
    padding: 8px 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #246DAF;
    display: flex;
    text-align: start;
    align-items: center;
}

.filter-block-content .label-filter{
    font-weight: normal;
    max-width: 155px;
    word-break: break-word;
    overflow: hidden;
    line-height: 12px;
}

.filter-item{
    display: flex;
    align-items: center;
    font-family: Montserrat;
}

.filter-block-icon div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.colorby{
    color: rgb(101, 101, 101);
    font-weight: bold;
    font-family: Montserrat;
    font-size: 12px;
}

/* finish css for filter CJ v2 */


.job-types-wrapper button {
    border-radius: 2px;
    font-weight: 900;
    font-size: 11px;
    background-color: #FFFFFF;
    color: #6B6B6B;
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.job-types-wrapper button.icons {
    padding: 0;
}
.button-event-area {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
}

.job-types-wrapper button .icon {
    padding: 0 6px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.job-types-wrapper button .icon:first-child {
    margin-left: 10px;
}

.filter-group>button>span {
    height: inherit;
}

.job-types-wrapper .top-ten-wrapper {
    margin: 0 8px 0 25px;
}

.scope-tooltip {
    z-index: 9999;
    position: fixed!important;
    max-height: calc(100% - 170px);
    overflow: auto;
    box-shadow: 0 0 8px 1px rgba(181, 181, 181, 0.25);
}

.scope-list-wrapper {
    padding: 5px 25px 5px 10px;
}
.scope-list-item {
    font-size: 15px;
    line-height: 27px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    text-align: left;
}
.scope-list-item:hover {
    text-decoration: underline;
    cursor: pointer;
}




button.icons .icon svg {
    fill: #A0A8B1!important;
}
.job-types-wrapper button:active,
.job-types-wrapper button:hover {
    background-color: #FAFAFA;
    color: #6B6B6B;
}

.job-types-wrapper button.active {
    background-color: #3876B7;
    color: #FAFAFA;
}

button.icons .icon.active {
    background-color: #3876B7;
}

button.icons .icon.active svg {
    fill: #FAFAFA!important;
}

button.icons .icon:active:not(.active) svg,
button.icons .icon:hover:not(.active) svg {
    fill: #3876B7!important;
}
button.icons .icon:not(.active) {
    background-color: #FAFAFA;
}

@keyframes show {
    from {opacity: 0;}
    to {opacity: 1;}
}

.arrow-pointing:after {
    display: block;
    content: '▾';
    padding: 0 2px;
    line-height: 14px;
}

/* .arrow-pointing.show:after {
    content: '▾';
} */

.filter-wrapper::-webkit-scrollbar {
    height: 4px;
}
.filter-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(169, 169, 169, 0.3);
    background-color: #F5F5F5;
    border-radius: 6px;
}
  
.filter-wrapper::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #A8A8A8;
}

img.arrow-down{
    width: 10px;
    margin-left: 5px;
}

.filter-block:first-child, .filter-block:last-child{
    min-width: 120px;
}
.filter-block.top-5{
    min-width: 144px;
}
.middle-block{
    min-width: 130px;
}

.middle-block-maplevel{
    min-width: 85px;
}

.middle-block-filter{
    min-width: 145px;
}
@media screen and (max-width: 1740px) {
    .filter-wrapper:not(.collapse) {
        left: calc(50vw - 620px)
    }
}

@media screen and (max-width: 1300px) {
    .filter-wrapper {
        max-width: calc(100% - 120px);
        overflow-x: scroll!important;
        overflow-y: hidden!important;
    }
    .filter-wrapper:not(.collapse) {
        left: 10px
    }
}

@media screen and (max-width: 800px) {
    .filter-wrapper {
        left: 55px;
    }
}

.is-mobile .filter-wrapper {
    max-width: calc(100% - 20px);
    left: 10px;
    border-radius: 8px 8px 0 0!important;
}
.is-mobile .filter-content,
.is-mobile .mapleve,
.is-mobile .scope-list,
.is-mobile .footer,
.is-mobile .scope-content {
    padding: 20px 15px;
}
.is-mobile .filter-block {
    padding: 10px 10px 5px;
    min-width: auto;
    min-height: 63px;
    max-height: 63px;
    box-sizing: border-box;
}
.is-mobile .filter-block-content {
    white-space: nowrap;
}

.is-mobile .label-filter {
    text-overflow: ellipsis;
}

.is-mobile .scope-tooltip {
    top: 146px!important;
    width: calc(100% - 20px);
    left: 10px!important;
    border-radius: 8px;
}
.is-mobile .middle-block{
    min-width: 80px;
}
.is-mobile .filter-radio {
    padding: 4px 10px 4px 6px!important;
}

.is-mobile .scope-list {
    overflow-x: auto;
    width: calc(100% - 75px);
}

.scope-tooltip::-webkit-scrollbar {
    width: 4px;
}
.scope-tooltip::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(169, 169, 169, 0.3);
    background-color: #F5F5F5;
    border-radius: 6px;
}
  
.scope-tooltip::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #A8A8A8;
}
.scope-tooltip{
    max-width: 460px;
    top: 150px!important;
}

.scope-list-wrapper{
    padding: 0;
}

.filter-radio{
    padding: 8px !important;
}

.filter-item .injected-svg{
    padding-right: 10px;
}

.maplevel{
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: flex-start;
    color: #6B6B6B;
    padding: 20px 25px 5px;
}

.level-item:hover{
    text-decoration: underline;
    cursor: pointer;
}

.filter-content, .scope-content{
    display: flex;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 15px 15px;
    border-radius: 5px;
}

.scope-content{
    background-color: rgba(196, 196, 196, 0.1);
}

.filter-item{
    text-align: start;
    font-size: 14px;
    cursor: pointer;
}

.filter-item .filterby-label{
    font-size: 11px;
    line-height: 15px;
    color: #686868;
    text-transform: capitalize;
}

.filter-item .filterby-label.blue{
    color: #2879B9;
}

.footer{
    padding: 15px 10px;
    border-top: 1px solid #C4C4C4;
    display: flex;
}

.footer-text{
    font-size: 12px;
    line-height: 14px;
    text-align: start;
    color: #000000;
}

.scope-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.scope-list {
    display: flex;
    padding-right: 0;
    align-items: flex-start;
    padding: 25px 25px 10px;
}

.scope-wrapper>.label {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #7A7A7A;
}
.scope-list .scope {
    display: flex;
    cursor: pointer;
    white-space: nowrap;
}
.scope-list .scope.show {
    animation: show .3s linear;
    animation-fill-mode: backwards;
}
.scope-list .scope.current>.value {
    font-weight: normal;
    font-weight: bold;
    color: #2879B9;
}
.scope-list .scope.hide {
    display: none;
}

.scope-list-item{
    font-weight: bold;
    font-size: 11px;
    color: #6B6B6B;
}
.scope>span.next {
    font-weight: normal!important;
    margin-left: 5px;
}

.next img.scope-arrow{
    width: 8px;
}

.scope-add {
    padding: 0 5px;
}
.scope-add button {
    background-color: #D8D8D8;
    border-radius: 0;
    padding: 0;
}
.scope-add button:hover {
    background-color: #D8D8D8;
}
.world-scope,
.country-scope,
.region-scope,
.province-scope,
.municipality-scope {
    margin-left: 8px;
    font-weight: normal;
    color: #686868;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
}

.key-elements{
    color: #2879B9;
    font-weight: bold;
    cursor: pointer;
}

span.value:hover{
    text-decoration: underline;
}

.region-scope{
    display: flex;
    justify-content: center;
}

.scope-select.country-margin,
.scope-select.region-margin,
.scope-select.province-margin{
    margin-left: 10px;
}

.close-btn {
    padding: 3px 6px;
    right: 7px;
    top: 8px;
}
.info-pane-wrapper {
    /* padding: 10px 15px; */
    z-index: 998;
    overflow-y: auto!important;
    height: 100%;
    width: 460px;
    border-radius: 0!important;
}
.button-row {
    display: flex;
    justify-content: space-around;
}
.info-pane-wrapper button {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 10px!important;
    font-weight: 600!important;
    width: 140px;
}
.info-pane-wrapper button.map-scope-button,
.info-pane-wrapper button.show-on-map-button {
    box-shadow: none;
    background-color: #EAEAEA;
}
.info-pane-wrapper button span.polygon-triangle_left {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #FFFFFF;
    margin-right: 5px;
}

.info-pane-wrapper .chart-wrapper .chart-margin {
    width: 260px;
    height: 260px;
    margin: 45px auto;
    margin-bottom: 60px;
}
.is-mobile .chart-margin {
    margin: 75px auto!important;
    width: 240px!important;
    height: 240px!important;
}

.chart-legend{
    padding: 20px 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
}

.legend-block{
    display: flex;
    height: 25px;
    align-items: center;
    margin: 0 5px;
}

.legend-block-center:only-child {
    margin: 0 auto;
}

.legend-block img{
    width: 25px;
    margin-right: 5px;
}

.legend-block .legend-label{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10.44px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2D2D2D;
}


.info-pane_header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.info-pane_header .province {
    padding: 25px 0;
}
.province .label {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 11px;
    color: #A5A5A5;
    padding: 5px 0;
}
.province .province-name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #263238;
    letter-spacing: 2px;
}
.province .compare {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #646464;
    position: relative;
}
.compare .compare-item {
    font-weight: 700;
}
.compare:not(.disabled) .compare-item {
    cursor: pointer;
}
.compare .polygon-triangle_bottom {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #C4C4C4;
    position: absolute;
    top: 4px;
}

.jobs-info-wrapper .wrapper {
    width: 100%;
}

.jobs-info-wrapper .label {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #475662;
    margin-top: 25px;
}

table.info-table {
    width: 94%;
}
table.info-table span.bold {
    font-weight: 700;
    text-transform: capitalize;
}
table.info-table span.bold.blue {
    color: #2879B9;
}
table.info-table tr>td {
    padding: 2px 6px;
    color: #686868;
}
table.info-table tr>td:first-child {
    text-align: right;
}

.compare-tooltip {
    right: 0;
    z-index: 999;
}
.compare-list-wrapper {
   padding: 7px 6px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   z-index: 1000;
}
.compare-list-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 26px;
    font-weight: normal;
    color: #000000;
    text-align: left;
    cursor: pointer;
}
.compare-list-item:hover {
    text-decoration: underline;
}
.compare-item_name {
    font-weight: bold;
}

.compare.disabled {
    opacity: .5;
}

.jobs-info-wrapper_print {
    display: none;
}

.circular-total-percent{
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular-total-percent .percent{
    padding: 10px;
    background: #7CE3C5;
    border-radius: 5px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

.circular-total .jobs{
    display: flex;
    padding: 2px 0 2px 10px;
}

.circular-total .jobs:first-child{
    border-bottom: 1px solid #CCCCCC;
}

.circular-total .jobs .jobs-label{
    margin-left: 10px;
}

.circular-total .jobs .jobs-count{
    min-width: 65px;
    text-align: right;
}

.circular-total .jobs:first-child{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #263238;
}

.circular-total .jobs{
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    color: #263238;
}
















.info-table .wrapper-block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 2px solid #E8E8E8;
}

.info-table .wrapper-block:last-child{
    border-bottom: none;
}

.info-table .wrapper-block .element-block{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    align-items: flex-start;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #686868;
    box-sizing: border-box;
}

.info-table .wrapper-block .element-block .element-visible{
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #3e464c;
    box-sizing: border-box;
    cursor: pointer;
    padding-top: 0;
    min-height: 55px;
}

.bold{
    font-weight: bold;
}

.blue{
    text-transform: uppercase;
    color: #2879B9;
}


.info-table .wrapper-block .element-block:not(:first-child){
    border-top: 1px solid #F3F3F3;
}


.block-name{
    text-align: start;
    width: 100%;
    font-size: 13px;
}

.block-total{
    margin: 0 5px;
    width: 110px;
    color: #000000;
}

.block-percent{
    width: 60px;
    flex-shrink: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #6FC9AF;
}

.block-percent.padding{
    background: #7CE3C5;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 5px;
    box-sizing: border-box;
}

.datatable-wrapper{
    text-align: start;
    margin-left: 40px;
    margin-top: 30px;
    width: calc(100% - 40px);
    padding: 10px 5px;
    box-sizing: border-box;
    background: rgba(155, 155, 155, 0.07);
}







.initiative-block{
    display: flex;
    padding: 30px 15px;
}

.initiative-img img{
    width: 145px;
}

.initiative-content{
    text-align: start;
    margin-left: 25px;
}

.initiative-title{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #2879B9;
}

.initiative-type{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    line-height: 11px;
    color: #6B6B6B;
}

.initiative-description{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
    margin: 15px 0;
}

.initiative-readmore{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 10px;
    color: #959595;
}

.initiative-wrapper{
    display: none;
}

.block-icon{
    margin-right: 10px;
    width: 15px;
}

.block-icon img{
    width: 8px;
    height: 8px;
}


.legend-select{
    position: relative;
}

.compare-list-wrapper {
    position: absolute;
    right: -55px;
    top: 30px;
    width: 285px;
    background-color: rgba(255, 255, 255, 0.95);
    color: #393939;
    box-shadow:         0 1px 3px 0 rgba(50, 50, 50, 0.75);
}

nav.compare-list {
    max-height: 240px;
    overflow-y: auto;
}
li.compare-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #393939;
    font-weight: 700;

    cursor: pointer;
}
li.compare-item:hover {
    background-color: rgba(0, 0, 0, .1);
}
li.compare-item.active {
    background-color: rgba(0, 0, 0, .1);
}
li.compare-item span.type {
    font-weight: 400;
    font-style: italic;
}

.compare-item-margin{
    padding-left: 10px;
}

.parents{
    border-bottom: 2px #dddddd80 solid;
}

.input-compare input{
    padding: 6px;
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10.44px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2D2D2D;
}

.list-item-text{
    /* font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #3a3839;
    font-weight: 700;
    cursor: pointer; */
}

.parents-scopes:first-child{
    background-color: transparent !important;
}


.info-pane-block{
    padding: 10px 15px;
}
.info-pane-block.not-selected {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #3E464C;
    top: 50%;
    position: absolute;
    width: 100%;
}
.info-pane-outer-wrapper.print .info-pane-block.not-selected {
    top: 90px;
}
.info-pane-outer-wrapper.print .info-pane-block {
    padding: 0;
}

.info-pane-block .share-link-wrapper {
    cursor: pointer;
}
.no-print .wrapper{
    margin-bottom: 25px;
}

.divider{
    height: 1px;
    background-color: #dcdcdc;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.CTCJV, .DFTF, .IDT, .PAEWAM, .PRR, .RTBM, .UWAAR, .IDRC {
    height: 53px;
    overflow: hidden;
    transition: height .3s;
}

.IDRC .element-visible{
    line-height: 30px !important;
}

.hide {
    display: none;
}

@media screen and (max-width: 440px) {
    .info-pane-wrapper {
        width: 335px;
    }
    .block-icon {
        margin-right: 5px;
    }
    .block-name {
        width: 115px;
        text-overflow: ellipsis;
/*         white-space: nowrap;
        overflow: hidden; */
        font-size: 11px;
    }
}


.info-pane-wrapper.is-mobile {
    width: 100%;
}
.info-pane-wrapper.is-mobile .info-pane_header {
    text-align: center;
}

.info-pane-wrapper.is-mobile .datatable-wrapper {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
}


@media screen and (max-width: 374px) {
    .info-table .wrapper-block .element-block .element-visible{
        line-height: 12px;
    }
    
    .block-name {
        font-size: 10px;
    }
    .block-total {
        font-size: 12px;
    }

    .circular-total-percent .percent {
        font-size: 18px;
    }
    .circular-total .jobs {
        font-size: 14px!important;
    }

    .is-mobile .chart-margin {
        width: 220px!important;
        height: 220px!important;
    }

    .radar-icon.rethink {
        top: 245px!important;
        right: 72px!important;
    }

    .radar-icon.design {
        top: 220px!important;
        left: 20px!important;
    }

    .radar-icon.waste {
        right: -20px!important;
        top: 172px!important;
    }

    .radar-icon.collaborate {
        top: 110px!important;
    }

    .is-mobile #index6 {
        left: -60px!important;
        top: -40px!important;
    }
    .is-mobile #index1 {
        width: 110px!important;
        left: -70px!important;
        top: -60px!important;
    }
    .is-mobile #index2 {
        right: -15px!important;
        top: 55px!important;
    }


}


.close-wrapper>div {
    cursor: pointer;
}

.print-wrapper>div {
    cursor: pointer;
}

.subtitle-print {
    display: none;
}


.share-print-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    padding: 10px 10px 0 0;
}

.share-print-wrapper svg {
    fill: #656565;
}


.share-print-wrapper svg:hover {
    fill: #3c7db7
}

.radar-chart-wrapper {
    position: relative;
}

.chartjs-render-monitor{
    transform: rotate(13deg);
}

.radar-chart-wrapper .radar-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
}

.radar-icon::after {
    content: '';
    opacity: 0;
    width: 1px;
    height: 13px;
    background-color: #D6D6D6;
    position: absolute;
}

.radar-icon .tooltip{
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    color: #1187B3;
}

[id^='icon-index']{
    transition: all ease-out .25s;
}

.core {
    background-color: #3CBEF1;
    width: 22px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.enabling {
    background-color: #2F85B1;
    width: 22px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.radar-icon.rethink {
    top: 269px;
    right: 83px;
}
.radar-icon.rethink::after {
    transform: rotate(-12deg);
    top: -13px;
    left: 9px;
}

#index3{
    opacity: 0;
    top: 15px;
    left: 20px;
    text-align: end;
    width: 90px;
}

.regenerative {
    top: -25px;
    right: 82px;
}
.regenerative::after  {
    transform: rotate(12deg);
    top: 23.8px;
    left: 9px;
}

#index0{
    opacity: 0;
    top: -25px;
    left: 5px;
    text-align: center;
    width: 135px;
}

.radar-icon.preserve {
    right: -18px;
    top: 61px;
}
.radar-icon.preserve::after {
    transform: rotate(-118deg);
    top: 14px;
    left: -5px;
}

#index1{
    opacity: 0;
    top: -50px;
    width: 130px;
    left: -35px;
    text-align: center;
}

.radar-icon.waste {
    right: -20px;
    top: 190px;
}
.radar-icon.waste::after {
    transform: rotate(-63deg);
    top: -2.2px;
    left: -5px;
}

#index2{
    opacity: 0;
    top: -40px;
    width: 90px;
    text-align: end;
    right: -60px;
}

.radar-icon.collaborate {
    left: -30px;
    top: 125px;
}
.radar-icon.collaborate::after {
    transform: rotate(90deg);
    top: 5.4px;
    left: 32.6px;
}

#index5{
    opacity: 0;
    top: 35px;
    text-align: center;
    width: 90px;
    right: -15px;
}

.radar-icon.digital {
    top: 10px;
    left: 26px;
}
.radar-icon.digital::after {
    transform: rotate(-38deg);
    top: 20px;
    left: 24.4px;
}

#index6{
    opacity: 0;
    top: -25px;
    width: 120px;
    left: -110px;
}

.radar-icon.design {
    top: 239px;
    left: 27px;
}
.radar-icon.design::after {
    transform: rotate(39deg);
    top: -9px;
    left: 25px;
}

#index4{
    opacity: 0;
    top: 20px;
    text-align: start;
    width: 90px;
    right: -15px;
}

.chartjs-render-monitor {
    -webkit-user-select: none;
            user-select: none;
}

canvas.chartjs-render-monitor {
    position: relative;z-index: 99;
}

#chartjs-tooltip{
    height: 60px;
    position: absolute;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #efefefb3;
    min-width: 90px;
    pointer-events: none;
    box-shadow: 0px 4px 14px rgba(51, 113, 110, 0.25);
    border-radius: 3px;
}

.img{
    width: 25px;
    height: 25px;
    margin: 0 5px;
}

.row{
    display: flex;
    align-items: center;
}

.is-mobile #index1 {
    left: -72px;
}
.is-mobile #index2 {
    top: -55px;
    right: -32px;
}
.is-mobile #index5 {
    right: -35px;
}
.is-mobile #index6 {
    left: -80px;
}
.datatable-sector{
    display: flex;
    padding: 10px 0;
}

.sector-name{
    width: 100%;
    font-size: 12px;
}

.sector-name .bold{
    font-weight: bold;
}

.sector-circularjobs{
    margin: 0 5px;
    width: 110px;
    text-align: center;
    color: #000000;
    font-size: 12px;
}

.sector-percent{
    width: 60px;
    flex-shrink: 0;
    text-align: center;
}

.block-svg{
    margin-right: 5px;
}

@media screen and (max-width: 440px) {
    .sector-name{
        font-size: 10px;
    }
    
}
.legend-wrapper {
    height: 139px;
    width: 130px;
    background: rgba(255, 255, 255, 0.9);

    position: absolute;
    bottom: 10px;
    right: 25px;
    z-index: 997;

    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
}

.legend-wrapper .color-pallet-wrapper {

}
.legend-wrapper .color-pallet-1 {
    min-width: 16px;
    height: 69px;
    background: linear-gradient(180deg, #93CFB8, #FFFFCD);
}

.legend-wrapper .color-pallet-2 {
    min-width: 16px;
    height: 52px;
    background: linear-gradient(180deg, #2E3790, #93CFB8);
}

.legend-wrapper .value-pallet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-right: 5px;
}
.value-pallet .value {
    font-family: 'Open Sans', sans-serif;;
    font-size: 12px;
    color: #626262;
    text-align: right;
}
.print .legend-wrapper {
    right: 10px!important;
}
.info-popup-wrapper_mobile .close-button { 
    position: absolute;
    top: -40px;
    right: 0;
    width: 35px;
    height: 35px;
    background-color: #FCFCFC;
    border-radius: 50%;
}
.mcb {
    height: 16px;
    width: 2px;
    margin-left: 17px;
    background-color: #263238;
    transform: rotate(45deg);
    border-radius: 2px;
    z-index: 1;
}
  
.cb {
    height: 16px;
    width: 2px;
    margin-top: 9px;
    background-color: #263238;
    transform: rotate(90deg);
    border-radius: 2px;
    z-index: 2;
}

.popup-content {
    padding: 10px 20px;
    min-height: 60px;
    border-radius: 10px 10px 0 0!important;
}

.selected-place-wrapper {
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 1.5;
    min-height: 45px;
}
.selected-place-wrapper .name {
    color: #246DAF;
    font-weight: bold;

}
.selected-place-wrapper .parent-name {
    color: #6B6B6B;
    font-size: 14px;
}

.decorative-line {
    position: absolute;
    height: 2px;
    width: 36px;
    background-color: #C4C4C4;
    top: 4px;
    left: calc(50% - 18px);
}

.jobs-info-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.5;
}
.jobs-info-wrapper .block-svg {
    height: 24px;
}
.jobs-info-wrapper .jobs-title,
.jobs-info-wrapper .jobs-data {
    flex-basis: 50%;
    text-align: left;
    font-size: 13px;
    color: #1A1A1A;
    opacity: 0.8;
}
.jobs-info-wrapper .jobs-data {
    font-size: 14px;
    padding-left: 40px
}


@media screen and (max-width: 374px) {
    .jobs-info-wrapper .jobs-data {
        font-size: 12px;
        padding-left: 20px;
    }
}
.info-pane-wrapper_mobile {
    
}
.info-pane-wrapper_dialog .close-button { 
    position: absolute;
    top: 10px;
    right: 14px;
    width: 36px;
    height: 36px;
    background-color: #FFF;
    border-radius: 50%;
    z-index: 9999;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
}
.mcb {
    height: 16px;
    width: 2px;
    margin-left: 17px;
    background-color: #263238;
    transform: rotate(45deg);
    border-radius: 2px;
    z-index: 1;
}
  
.cb {
    height: 16px;
    width: 2px;
    margin-top: 9px;
    background-color: #263238;
    transform: rotate(90deg);
    border-radius: 2px;
    z-index: 2;
}

.info-pane-wrapper_dialog  div[role="dialog"] {
    bottom: 0;
    position: absolute;
    margin: 0!important;
}
.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 68px)!important;
}
.dialog {
    z-index: 9999!important;
}

.methodology-explanation-dialog{
    padding: 0 !important;
    overflow: hidden !important;
    font-size: 15px;
    line-height: 18px;
    color: #292929;
    height: 100vh;
    width: 959px;
}

div.MuiDialog-paper {
    margin: 0;
}

div.MuiDialog-container.MuiDialog-scrollPaper {
    align-items: flex-start;
    justify-content: flex-end;
    height: 100vh;
    max-height: 100vh;
}

div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
    max-height: 100vh !important;
    border-radius: 0;
}

.MuiDialog-paper{
    overflow: hidden !important;
}

.box{
    height: 100%;
    padding: 0 !important;
}

.close-btn{
    position: absolute;
    right: 20px;
    bottom: 14px;
    cursor: pointer;
    padding: 3px 6px;
    height: 36px;
}


.scroll-btn{
    display: block;
    cursor: pointer;
    margin-right: 8px;
    float: right;
    width: 36px;
    height: 36px;
}

@media screen and (max-width: 1025px) {
}

span.MuiTab-wrapper {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: flex-start;
    text-align: left;
    text-transform: none;
    width: 331px;
}

div.MuiTabs-flexContainer.MuiTabs-flexContainerVertical{
    margin: 0;
    margin-top: 60px;
    width: 331px;
}


button.MuiTab-root {
    padding: 0;
    padding-left: 20px;
    height: 41px;
    max-width: 100%;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;

    color: rgba(38, 50, 56, 1);
    opacity: 1;
}

button.MuiTab-root.heading-tab {
    font-weight: bold;
    font-size: 13px;
    line-height: 30px;
    text-transform: uppercase;
    color: rgba(17, 135, 179, 1);
    opacity: 1;
}

button.MuiTab-root.heading-tab span.MuiTab-wrapper {
    text-transform: uppercase;
}

button.MuiTab-root.active {
    background: #1187B3;
    color: #FFF;
}

div.MuiTabs-root {
    border-right: 1px solid #C4C4C4;
}

div.MuiBox-root {
    margin-top: 60px;
    width: 100%;
    
}

h1.MuiTypography-h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #263238;
    opacity: 1;
}

h1.MuiTypography-h1.special {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
}

h1.MuiTypography-h1.bottom {
    font-weight: bold;
}

h2.MuiTypography-h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #263238;
    opacity: 1;
    margin-top: 45px;

}

p.MuiTypography-body1, li {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #474747;
    opacity: 1;
}

p.MuiTypography-body1 span.highlight {
    color: #1187B3;
    cursor: pointer;
}

p.MuiTypography-body1 span.highlight:hover {
    text-decoration: underline;
}


span.MuiTypography-button {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: none;
    color: #1187B3;
    opacity: 1;
}

ul {
    padding-left: 25px;
    margin-top: 0;
}

div.MuiBox-root.tab-panel {
    margin-left: 53px;
    width: 508px;
    max-width: 508px;
}

/* @media screen and (max-width: 1025px) {
    
} */
div[role=tabpanel]{
    height: 100vh;
    padding-right: 64px;
    overflow: auto !important;
}

@media screen and (max-width: 680px){
    span.MuiTab-wrapper {
        width: 397px;
    }
    
    div.MuiTabs-flexContainer.MuiTabs-flexContainerVertical{
        width: 397px;
    }

    div.MuiTabs-flexContainer.MuiTabs-flexContainerVertical{
        margin-top: 7px;
    }

    div.MuiBox-root.tab-panel {
        width: 312px;
        max-width: 397px;
        margin-left: 0px;
    }

    div.MuiDialogContent-root.methodology-explanation-dialog {
        overflow-y: auto !important;
        width: 397px;
    }

    div[role=tabpanel]{
        overflow: hidden !important;
        max-width: 397px;
        width: 397px;
        border-bottom: 1px solid #C4C4C4;
        height: auto;
        margin-left: 20px;
    }

    div.MuiTabs-root {
        border-right: none;
        border-bottom: 1px solid #C4C4C4;
    }

    div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
        margin-left: 18px;
    }

    p.MuiTypography-body1 {
        width: 312px;
    }

    li {
        width: 287px;
    }

}
.share-link-dialog {
    padding: 20px 40px 20px;
    width: 540px;
}
.share-link-dialog .header-title {
    padding: 12px 0 16px;
}

.header-title b {
    font-weight: 900;
}

.link-wrapper {
    display: flex;
    align-items: center;
}

.link-wrapper .MuiInputBase-root {
    flex: 1 1;
}

.link-wrapper input{
    padding: 12px 6px;
    background: #f7fafc;
    border: 1px solid #e5eaf1;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10.44px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #3c7db7;
}

.link-wrapper .copy-button {
    padding-left: 18px;
    cursor: pointer;
    fill: #656565;
}

.link-wrapper .copy-button:hover {
    fill: #3c7db7;
}
