.datatable-sector{
    display: flex;
    padding: 10px 0;
}

.sector-name{
    width: 100%;
    font-size: 12px;
}

.sector-name .bold{
    font-weight: bold;
}

.sector-circularjobs{
    margin: 0 5px;
    width: 110px;
    text-align: center;
    color: #000000;
    font-size: 12px;
}

.sector-percent{
    width: 60px;
    flex-shrink: 0;
    text-align: center;
}

.block-svg{
    margin-right: 5px;
}

@media screen and (max-width: 440px) {
    .sector-name{
        font-size: 10px;
    }
    
}