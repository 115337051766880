.map-wrapper {
    position: relative;
}

.leaflet-container {
    height: calc(100vh - 61px);
    width: 100%;
    margin: 0 auto;
}

.hover-balloon {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}
 .title-name {
    font-weight: 700!important;
    font-family: 'Montserrat', sans-serif;
     font-size: 14px;
     color: #006989;
}
.hover-balloon .subtitle-name {
    font-size: 11px;
}
.hover-balloon .jobs {
    font-weight: bold;
    padding-top: 20px;
}
.leaflet-popup-close-button {
    display: none;
}
.leaflet-popup.leaflet-zoom-animated {
    pointer-events: none;
}

.leaflet-marker-icon {
    background: unset!important;
    border: none!important;
    margin-left: -12px!important;
}
.leaflet-marker-icon .top-five-marker,
.leaflet-marker-icon .pin-marker {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #D75E3B;
    position: relative;
    transform: rotate(-45deg);
    margin: -20px 0 0 -5px;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
    box-shadow: -1px 1px 1px 1px rgba(41, 41, 41, 0.62);
}
.leaflet-marker-icon .top-five-marker:after {
    content: '';
    width: 18px;
    height: 18px;
    margin: -12px 0 0 -9px;
    background: #FFF;
    position: absolute;
    border-radius: 50%;
}
.top-five-marker>.text {
    display: block;
    position: relative;
    transform: rotate(45deg);
    color: #D75E3B;
    font-weight: 700;
    top: 6px;
    left: 0;
    z-index: 2;
}

.leaflet-marker-icon .pin-marker {
  background: #343E44;
}
.leaflet-marker-icon .marker-color {
  position: absolute;
  content: '';
  width: 22px;
  height: 22px;
  background: inherit;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}

.map-components-wrapper {
    width: 100%;
    position: relative;
    float: left;
}
.map-components-wrapper.infopane {
  width: calc(100vw - 460px);
}
.info-pane-outer-wrapper {
    height: calc(100vh - 61px);
    display: inline-block;
    position: absolute;
    right: 0;

    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow:    0 0 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow:         0 0 4px 0 rgba(0, 0, 0, 0.25);

    z-index: 997;
}

.collapse-button {
    width: 18px;
    height: 40px;
    background-color: #FFF;

    position: absolute;
    left: -19px;
    top: 50px;
    cursor: pointer;
    user-select: none;
    z-index: 999;

    -webkit-box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow:    -2px 0 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow:         -2px 0 4px 0 rgba(0, 0, 0, 0.25);
}
.collapse-button:after {
    content: '';
    position: absolute;
    top: calc(50% - 8px);
    right: calc(50% - 4px);

    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #888888;
}
.collapse-button.open:after {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent; 
    border-right: 8px solid #888888;
    border-left: none;
}

.print-header {
    display: none;
}

.map-components-wrapper.print {
    width: 210mm;
}
.map-components-wrapper.print #map {
    height: 52vh;
    min-height: 400px
}

.print-content-wrapper {
    position: absolute;
    left: 0;
    top: -130px;
    width: 240px;
}
.print-content-wrapper .print-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;

    padding: 15px 0;
}
.print-content-wrapper button {
    font-weight: 700;
    margin: 10px 10px 10px 0;
    color: #414141;
    font-size: 12px;
}
.print-content-wrapper .button-row {
    justify-content: flex-start;
}
button.print-button,
button.print-button:active,
button.print-button:hover {
    border-radius: 5px;
    background-color: #4273B5;
    color: #FFF;
}
.info-pane-outer-wrapper.print {
    display: none;
}


/* print */
.map-wrapper.print .no-print {
    display: none;
}
.map-wrapper.print {
    width: 210mm;
    height: 297mm;

    display: flex;
    flex-direction: column;
    margin: 146px 5px 5px 20px;
    padding: 25px;
    -webkit-box-shadow: 1px 1px 5px 0 rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    1px 1px 5px 0 rgba(50, 50, 50, 0.75);
    box-shadow:         1px 1px 5px 0 rgba(50, 50, 50, 0.75);
}
.map-wrapper.print .collapse-button {
    display: none;
}
.map-wrapper.print .print-header {
    display: block;
}
.map-wrapper.print .button-row.no-print {
    display: none!important;
}
.map-wrapper.print .jobs-info-wrapper_print{
    display: flex!important;
    justify-content: space-between!important;
    width: 100%!important;
    position: absolute!important;
    bottom: 0px!important;
    padding-bottom: 0px!important;
}

.map-wrapper .jobs-info-wrapper_print.hide {
  display: none!important;
}

.map-wrapper.print .jobs-info-wrapper {
    display: none;
}
.map-wrapper.print .info-pane-outer-wrapper {
    display: block;
    position: unset!important;
    box-shadow: none!important;
    height: 52%!important;

    border-top: 1px solid #CACACA!important;
    padding-top: 10px!important;
}
.map-wrapper.print .info-pane-wrapper {
    width: auto!important;
    position: relative!important;
    height: 100%!important;
    box-shadow: none!important;
    overflow: hidden!important;
    padding: 0;
}
.map-components-wrapper.print {
    width: 100%;
    height: calc(40% - 22px);
    margin-bottom: 10px;
}

.map-wrapper.print .print-header {
    background-color: #FFF!important;
    color: #2D2D2D;
    font-family: 'Montserrat', sans-serif !important;
    text-align: left!important;

    display: flex!important;
    flex-direction: column!important;
    justify-content: space-between!important;
    padding: 10px 0 1px 0;
    min-height: 42px;
}
.map-wrapper.print .print-header>.subtitle {
    display: block!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: normal!important;
    font-size: 22px!important;
    letter-spacing: 0.1em!important;
    text-transform: uppercase!important;
    color: #2D2D2D!important;
}
.map-wrapper.print .subtitle-print {
    display: block!important;
    font-size: 12px!important;
    color: #000!important;
    text-align: left;
    padding: 2px 0 4px;
}

.map-wrapper.print .jobs-info-wrapper_print .left {
    flex: 1!important;
  }
.map-wrapper.print .total_print {
    text-align: left!important
}
.map-wrapper.print .jobs-info-wrapper_print .right {
    flex: 1!important;
    padding-top: 176px!important;
}
.map-wrapper.print .info-table.top {
    margin: 40px 0 60px 0!important;
}
.map-wrapper.print .info-table.top .tc {
    font-size: 17px!important;
    padding: 0;
}
.map-wrapper.print .info-table tr {
    height: 38px!important;
}
.map-wrapper.print .tc {
    text-align: left!important;
    min-width: 160px!important;
}

.map-wrapper.print td.td-icon {
    width: 25px!important;
}
.map-wrapper.print td.td-percent {
    width: 25px!important;
}
.map-wrapper.print table.info-table.top td {
    font-size: 16px;
}
.map-wrapper.print table.info-table.top td.td-percent {
    font-weight: bold;
    color: #2879B9;
}
.map-wrapper.print .chart-wrapper {
    position: absolute!important;
    right: 0!important;
    padding: 0px calc(25% - 125px)!important;
    margin: 0!important;
    top: -10px!important;
}

.map-wrapper.print .info-pane_header {
    position: absolute!important;
    width: 50%!important;
    left: 0!important;

    display: flex!important;
    justify-content: flex-start!important;

}

.map-components-wrapper.print #map {
    height: 100%;
}

.map-wrapper.print .info-pane_header .label {
    color: #000!important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300!important;
    font-size: 14px!important;
    text-align: left!important;
  }
  .map-wrapper.print .info-pane_header .province-name {
    color: #2D2D2D!important;
    text-transform: uppercase!important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500!important;
    font-size: 24px!important;
    text-align: left!important;
    letter-spacing: 0.1em!important;
  }


  .map-wrapper.print .info-table.borderless td,
  .map-wrapper.print tbody>tr:last-child>td {
    border: none;
  }
  
  .leaflet-control-zoom-in:after, .leaflet-control-zoom-out:after {
    opacity: 0;
  }

  .loading{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 9999;
  }

  .loading .spinner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
  }

  .loading .spinner .spinner-text{
      margin-left: 25px;
      color: #909090;
      font-size: 21px;
      font-weight: 700;
  }

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #3776b7;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .leaflet-bottom {
      margin-bottom: 32px !important;
  }

  .info-popup-wrapper_mobile {
    position: absolute;
    bottom: 0;
    width: calc(100vw - 20px);
    box-sizing: border-box;
    margin: 0 10px;
    z-index: 999;
  }